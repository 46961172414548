import React from "react";
import TagManager from "react-gtm-module";

function GoogleTag(id) {
  const tagManagerArgs = {
    gtmId: id,
  };

  TagManager.initialize(tagManagerArgs);
  console.log("TagManager", id);
}

export default GoogleTag;
