import React, { createContext, useEffect, useReducer, useState } from "react";

export const Cartcontext = createContext();

export const Context = (props) => {
  const initialCartState = JSON.parse(localStorage.getItem("cartData")) || [];

  const cartReducer = (state, action) => {
    switch (action.type) {
      case "ADD":
        const tempstate = state.filter(
          (item) => action.payload.gid === item.gid
        );
        if (tempstate.length > 0) {
          return state;
        } else {
          return [...state, action.payload];
        }
      case "INCREASE":
        return state.map((item) =>
          item.gid === action.payload.gid
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      case "DECREASE":
        return state.map((item) =>
          item.gid === action.payload.gid
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      case "RESET":
        return [];
      case "REMOVE":
        return state.filter((item) => item.gid !== action.payload.gid);
      default:
        return state;
    }
  };

  const [cartState, cartDispatch] = useReducer(cartReducer, initialCartState);

  const [color, setColor] = useState({});
  const [storeData, setstoreData] = useState({});

  useEffect(() => {
    localStorage.setItem("cartData", JSON.stringify(cartState));
  }, [cartState]);

  const value = {
    state: cartState,
    dispatch: cartDispatch,
    color,
    setColor,
    storeData,
    setstoreData,
  };

  return (
    <Cartcontext.Provider value={value}>{props.children}</Cartcontext.Provider>
  );
};
