import React from "react";
import ReactGA from "react-ga4";

let is_running = false;
function GoogleAnalytic(id) {
  if (is_running == true) {
    return;
  }
  is_running = true;
  ReactGA.initialize(id);
  console.log("GoogleAnalytic", id);
  is_running = false;
  return;
}

export default GoogleAnalytic;
